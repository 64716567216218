import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PrivatePage from '../../../components/PrivatePage';
import CalendarViewItem from '../Calendar/CalendarListView/CalendarViewItem';
import CalendarPage from '../Calendar/CalendarPage';
import CalendarContextProvider from '../Calendar/CalendarGlobalState';
import useHomePage from '../../../hooks/HomePage/useHomePage';
import ProgressBar from '../Common/ProgressBar';
import ProgressBarMobile from '../Common/ProgressBarMobile';
import useCheckMobileTablet from '../../../../utils/useCheckMobileTablet';
import arrowIcon from '../../../../assets/image/arrow-green.png';
import HomePageMobile from './HomePageMobile';
import ModuleWidgetModular from './ModuleWidgetModular';
import Modal from '../Dialog/Modal';
import { Button } from '@material-ui/core';
import rectangleIcon from '../../../../assets/image/rectangle.png';
import CircularProgress from '../Common/CircularProgress';
import ReactHtmlParser from 'react-html-parser';

import '../../../../sass/private/Home/Home.scss';
import '../../../../sass/private/CalendarPage/PracticeViewItem.scss';
import { setOpenBookingWindow } from '../../../redux/actions/Booking/BookingAction';
import { IFrame } from './iframe';
import { decode } from 'html-entities';

import StudentAccount from '../StudentAccount/StudentAccount';

function onActiveNewsFeed(listNews, indexActive) {
  return listNews?.filter((item, index) => {
    if(window.innerWidth <=1024) {
      if(index === indexActive) {
        return item
      }
    }
    else {
      if((index >= indexActive) && (index <= indexActive + 1)) {
        return item
      }
    }
    return ''
  })
}
const Home = () => {
  const { landingData, newsData, newsFeedData, newsFeedLogin, onRedirectBookingWidow, openNewsFeed, onStatusNewsFeed, isLoadingLandingData, reloadData, isLoadingNewsFeed, dataReloadTokenOthers, countDownStartIn } = useHomePage();
  const { isTablet } = useCheckMobileTablet();
  const avatar = useSelector(state => state.auth.avatar);
  const firstName = useSelector(state => state.auth.user?.last_name) || "";
  const [open, setOpen] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const isMounted = useRef(true);
  const filterProductId = useRef();
  const arr = []
  newsData?.forEach((element, index) => {
    const i = index;
    return arr.push(i)
  });
  const [dotCurrent, setDotCurrent] = useState(0)

  const [indexNewsActice, setIndexNewsActive] = useState(0)
  const [isRemember, setIsRemember] = useState(false)
  const [news, setNews] = useState(null)
  const [checkNewsFeed, setCheckNewsFeed] = useState({})
  const lengthDotArr = arr.length
  const [openCredit, setopenCredit] = useState(false)
  
  useEffect(() => {
    if(newsData?.length > 0) {
      let showNews = [...newsData];
      const newsItem = onActiveNewsFeed(showNews, indexNewsActice);
      setNews(newsItem)
    }
  }, [newsData, indexNewsActice, checkNewsFeed]);

  useEffect(() => {
    if(newsFeedLogin) {
      setOpenLogin(true)
    }
  }, [newsFeedLogin]);

  const onNextPrev = (value, event) => {
    event?.stopPropagation();

    if(value === 1) {
      setIndexNewsActive(indexNewsActice + 1)
    }else {
      setIndexNewsActive(indexNewsActice - 1)
    }

    if (dotCurrent === 0 && value === -1 ) {
      setDotCurrent(lengthDotArr)
    } else if (dotCurrent === lengthDotArr && value === 1){
      setDotCurrent(arr[0])
    } else{
      setDotCurrent((prev) => prev + value)
    }
  }

  const onClickDot = (value) => {
    setDotCurrent(value)
    setIndexNewsActive(value)
  }

  useEffect(() => {
    return () => {
      isMounted.current = false;
    }
  }, [])

  const handleClickOpen = (id) => {
    setOpen(true)
    openNewsFeed(id)
  }
  const handleCloseDialog = () => {
    setOpen(false);
    setOpenLogin(false);
  };
  const onChangeRemember = () => {
    setIsRemember(!isRemember)
    let newDataCheck = {...checkNewsFeed}
    newDataCheck = {...checkNewsFeed, news_feed_id: newsFeedLogin.id, show_next_time: isRemember}
    onStatusNewsFeed(newDataCheck)
  }

  const modalNewsFeed = (newObject, openObject) => {
    return (
        <Modal open={openObject} handleCloseDialog={handleCloseDialog} className="news-feed-modal">
          <div className="modal-newsFeed__wrapper">
            <div className="modal-newsFeed__header">
              <h2>News</h2>
            </div>
            {isLoadingNewsFeed && <CircularProgress />}
            {!isLoadingNewsFeed && <div className="modal-newsFeed__body">
              <div className="date">{newObject.start_date}</div>
              <div className="title">{newObject.title}</div>
              {openObject &&
                <IFrame>{ReactHtmlParser(decode(newObject.content || ""), {
                  transform: function(node) {
                    if (node.type === "tag" && node.name === "a") {
                      node.attribs.target = "_blank";
                    }
                  }
                })}
                </IFrame>
              }
            </div>}
            <div className="modal-newsFeed__footer">
              {newObject.display_dont_show_me_checkbox === 1 &&
                <div className="remember-block">
                  <div className="remember-icon" onClick={onChangeRemember}>
                    {isRemember?
                      <img src={rectangleIcon} alt="remember" className="center"/> :
                      <div className="remember-icon__radio center"></div>
                    }
                  </div>
                  <div className="remember-text">Got it, don't show me again</div>
                </div>
              }

              <Button variant="contained" className="btn btn-default" onClick={handleCloseDialog}>
                Close
              </Button>
            </div>
          </div>
        </Modal>
    )
  }
  const handleModel = (id) =>{
    setopenCredit(true)
    filterProductId.current = id;
  }
  return (
    <PrivatePage className="Home" isShowMainNavigation={true}>
      <div className="home-header">
        { newsData && newsData?.length > 0 && 
          <div className="new-title">
            {
              news?.map((newsItem, index) => (
                  <p key={index} tabIndex={0} className="newsfeed" onClick={()=>handleClickOpen(newsItem?.id)} onKeyPress={()=>handleClickOpen(newsItem?.id)}>{newsItem?.title}</p>
              ))
            }
            <div className="slider-block">
              {newsData.length > 2 && <div style={{width: '20px'}}>

                {!isTablet && indexNewsActice !== 0 && <img src={arrowIcon} alt="Previous" title="Previous" onClick={(e)=>onNextPrev(-1, e)} />}
              </div>}
              {arr.map(item => {
                return <div 
                        key={item}
                        tabIndex={0}
                        className={`dot ${item === dotCurrent ? 'active' : ''}`} 
                        onClick={() => onClickDot(item)}
                        onKeyPress={() => onClickDot(item)}
                        ></div>
              })}
              {newsData.length > 2 && <div style={{width: '20px'}}>

                {!isTablet && indexNewsActice !== newsData.length -1 && <img src={arrowIcon} alt="Next" title="Next" className="img-next" onClick={(e)=> onNextPrev(1, e)}/>}
              </div>}
            </div>
          </div>
        }

        {!isTablet && 
          <div className="user-info">
            <div className="user-info-content">
            <div className="user-info__left">
              <div className="user-avatar">
                <div className="user-avatar__img">
                  {
                    avatar !== ""?
                    <img src={avatar} alt="Student profile picture" className="iconAvatar"/> :
                    <div 
                      className="iconText" 
                    >
                      {firstName.charAt(0).toUpperCase()}
                    </div>
                  }
                </div>
                <div className="user-avatar__name">Hello {firstName}</div>
              </div>
              {landingData.moduleWidget?.length > 0 &&
                landingData.moduleWidget.map((lessonItm) => (
                  <div key={lessonItm.productId} className="user-info__progress">
                    {!lessonItm.notStarted && lessonItm.nonModular.lessonTotal > 0 &&
                      <>
                        <ProgressBar label="Upper-intermediate" total={lessonItm.nonModular.lessonTotal} count={lessonItm.nonModular.lessonAttended} isHideLable={true} isHideInfo={true} isLocked={lessonItm.notStarted}/>
                        <div className="progress-bar__infor">
                          <p>{lessonItm.productName} lesson target</p>
                          <p>{lessonItm.nonModular.lessonAttended} out of {lessonItm.nonModular.lessonTotal} lessons attended</p>
                        </div>
                      </>
                    }
                    {!lessonItm.notStarted && <ModuleWidgetModular data={lessonItm.modular} />}
                  </div>
                ))
              }
            </div>

            <div className="user-info__right">
              {landingData.showPathway && <div className="progress-pathway">
                <a href='progress-details' className="progress">
                  <p className="progress-pathway__text">Pathway themes in progress</p>
                  <p className="progress-pathway__total">{landingData.pathways?.inProgress}</p>
                </a>
              </div>}
              {
                  landingData.credits?.length > 0 && 
                  landingData.credits.map((creditItm, index) => (
                    <div
                      className={`credit-block credit-hover`}
                      key={creditItm.productId}
                      onClick={() => handleModel(creditItm.productId)}
                    >
                        <a className="balance"
                          tabIndex={creditItm.productId == 1 ? 0 : undefined}
                          onKeyPress={() => creditItm.productId == 1 ? handleModel(creditItm.productId) : undefined}
                        >
                          <div>
                            <p className="credit-balance">{creditItm.productName} credit balance: <span>{creditItm.creditBalance}</span></p>
                          </div>
                          {Array.isArray(creditItm.creditExpirings) && creditItm.creditExpirings.map((item, index) =>
                            <p key={index} className="credit-expiring">{item}</p>
                          )}
                        </a>
                    </div>
                  ))
                }
                <Modal
                  open={openCredit}
                  handleCloseDialog={handleCloseDialog}
                  useThemeContact={false}
                >
                  <StudentAccount productTypeId={filterProductId.current} onClose={() => setopenCredit(false)}/>
                </Modal>
            </div>
            </div>
          </div>
        }
        
        {isTablet && 
          <div className="user-info-mobile">
            <div className="info-top">
              <div className="user-avatar__img">
                {avatar !== "" && <img src={avatar} alt={firstName} className="--img"/>}
              </div>
              <div className="user-avatar__name">
                <div className="--firstname">Hello {firstName}</div>
                {
                  landingData.credits?.length > 0 && 
                  landingData.credits.map((creditItm) => (
                    <div className="--credit" key={creditItm.productId}>{creditItm.productName} credit balance: {creditItm.creditBalance} 
                    {Array.isArray(creditItm.creditExpirings) && creditItm.creditExpirings.map((item, index) =>
                      <div key={index} className="--expires">{item}</div>
                    )}
                    </div>
                  ))
                }
              </div>
            </div>

            {landingData.moduleWidget?.length > 0 &&
                landingData.moduleWidget.map((lessonItm) => (
                  <div key={lessonItm.productId} className="info-bottom">
                    {!lessonItm.notStarted && lessonItm.nonModular.lessonTotal > 0 &&
                      <>
                        <ProgressBarMobile label="Upper-intermediate" total={lessonItm.nonModular.lessonTotal} count={lessonItm.nonModular.lessonAttended} isHideLable={true} isHideInfo={true}/>
                        <div className="progress-bar-des">
                          <p><span>{lessonItm.productName}</span> lesson target </p>
                          <p>{lessonItm.nonModular.lessonAttended} out of {lessonItm.nonModular.lessonTotal} lessons attended</p>
                        </div>
                      </>
                    }
                    <ModuleWidgetModular data={lessonItm.modular} />
                  </div>
                ))
              }
          </div>
        }
      </div>

      {isTablet ?
        <HomePageMobile
          landingData={landingData}
          isLoadingLandingData={isLoadingLandingData}
          reloadData={reloadData}
          dataReloadTokenOthers={dataReloadTokenOthers}
        />
      :(
        <div className="home-page">
      <>
        {isLoadingLandingData && <CircularProgress />}
        {!isLoadingLandingData && <div className="next-class">
          {
            landingData && landingData.nextClass ? (
              <>
                <div className="title">Your next class</div>
                <CalendarViewItem
                  title={landingData.nextClass?.productType}
                  desc={landingData.nextClass?.title}
                  time={landingData.nextClass?.infoLines.title}
                  active={landingData.nextClass?.actionArea.showButtonLessonDetail}
                  borderColor={landingData.nextClass?.colorHex}
                  isNextClass
                  countDownStartIn={countDownStartIn}
                  isPastEndTime={landingData.nextClass.isPastEndTime || false}
                  showStartIn={landingData.nextClass?.showStartIn}
                  showJoinNow={landingData.nextClass.actionArea?.showButtonJoinClass}
                  startIn={landingData.nextClass?.startIn}
                  joinNowText={landingData.nextClass.actionArea?.textJoinClassForLessonBlock}
                  joinLink={landingData.nextClass.actionArea?.linkJoinClass}
                  onRedirectBookingWidow={onRedirectBookingWidow}
                  lessonTimeId={landingData.nextClass?.lessonTimeId}
                  teacher={landingData.nextClass?.infoLines.centreTeacherName}
                  friendIcon={landingData.nextClass.iconSet?.friend?.showIcon}
                  levelsIcon={landingData.nextClass.otherLevel}
                  wishListIcon={landingData.nextClass.iconSet?.wishList?.showIcon}
                  workPlaceIcon={landingData.nextClass.iconSet?.workPlace?.showIcon}
                  ticked={landingData.nextClass.ticked}
                  isLocked={landingData.nextClass.isLocked}
                  availabilityText={landingData.nextClass.infoLines?.availability}
                  showAlertIcon={landingData.nextClass.infoLines?.fullWarningIcon}
                  module={landingData.nextClass?.module}
                  theme={landingData.nextClass?.theme_object}
                />
              </>
            ) : (
              <div className="no-next-class">
                You have not got any classes booked. Find classes using the calendar below.
              </div>
            )
          }
        </div>}
      </>

        <CalendarContextProvider>
          <CalendarPage 
            reloadData={reloadData} 
            dataReloadTokenOthers={dataReloadTokenOthers} 
            isLoadingLandingData={isLoadingLandingData}
            landingData={landingData}
          />
        </CalendarContextProvider>
        </div>
      )}

      {newsFeedLogin.show_at_login === 1 && modalNewsFeed(newsFeedLogin, openLogin)}
      {newsFeedData && modalNewsFeed(newsFeedData, open)}
    </PrivatePage>
  )
}

export default Home;

function Progress(props) {
  const { value, name, text } = props;
  return (
    <div className="progress">
      <div className="progress-value">{value}</div>
      <div>
        <div className="progress-name">{name}</div>
        {text && <div className="progress-text">{text}</div>}
      </div>
    </div>
  )
}
