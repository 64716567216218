import { memo } from 'react';
import ProfileWraper from '../Profile/ProfileWraper';
import Accordion from './Accordion';
import { Button } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import useBookingPage from '../../../hooks/BookingPage/useBookingPage';
import useCheckMobileTablet from '../../../../utils/useCheckMobileTablet';
// import ProgressBarPathway from '../Common/ProgressBarPathway';
import CircularProgress from '../Common/CircularProgress';
import Tooltip from '../Common/Tooltip';
import { windowOpen } from '../../../components/Utilities';
import Check from '../../../components/icon/Check';

import '../../../../sass/private/Booking/Booking.scss';

import Users from '../../../components/icon/Users';
import Level from '../../../components/icon/Level/index';
import Workplace from '../../../components/icon/Workplace';
import Star from '../../../components/icon/Star';

import clockIcon from '../../../../assets/svg/clock.svg';
import monitorIcon from '../../../../assets/svg/monitor.svg';
import alertCircleIcon from '../../../../assets/svg/alert-circle.svg';
import mapPinIcon from '../../../../assets/svg/map-pin.svg';
import iconMissing from '../../../../assets/svg/alert-triangle.svg';
import ArrowLeft from '../../../components/icon/ArrowLeft';
import BookingProgressBar from './BookingProgressBar';
import AlertCircle from '../../../components/icon/AlertCircle';
import Layers from '../../../components/icon/Layers';
import Iconnews from "../../../../assets/image/new-icon.png";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const BookingPage = (props) => {
  const { isTablet, isMobile } = useCheckMobileTablet()
  const {
    isBooking,
    isShowBtnBook,
    isDisableBtnBook,
    isShowBtnCancel,
    isShowIconFriend,
    isShowIconLevel,
    isShowIconWorkplace,
    msgError,
    onRedirectPage,
    proceedBooking,
    isShowConfirm,
    setIsShowConfirm,
    confirmMessage,
    bookingList,
    onChangeAccordion,
    bookingWindowData,
    isLoading,
    showJoinNow,
    joinLink,
    joinNowText,
    isShowWishlistToggle,
    isInWishlist,
    toggleWishlist,
    isDisableBtnCancel,
    bookingIsClosed
  } = useBookingPage(props.reloadData);
  const history = useHistory();
  const { onCloseBooking, handleOpenModuleDialog, isShowHeader=false } = props;

  const isPathway = !isTablet && bookingWindowData.hasPathway;
  const isLessonTaken = bookingWindowData.ticked;

  const credits = parseInt(bookingWindowData.credits) || 0;
  const bookingListEnabled = bookingList.filter((item) => item.enabled);
  const module = bookingWindowData.module;
  const isBookedWithModule = bookingWindowData.is_booked_with_module;
  const handleOpenModuleDetails = (id) => {
    if (isTablet) {
      const pathname = history.location.pathname;
      history.push(pathname);
    }
    onCloseBooking();
    handleOpenModuleDialog(id);
  }
  const categoryColor = bookingWindowData?.theme_object?.left_font_color;
  const typeColor = bookingWindowData?.theme_object?.right_font_color;
  const categoryBackgroundColor = bookingWindowData?.theme_object?.product_category_color;
  const typeBackgroundColor = bookingWindowData?.theme_object?.product_type_color;
  const newIcon = bookingWindowData?.theme_object?.is_new;
  return (
    <ProfileWraper
      onClose={onCloseBooking}
      title="Lesson details"
      isHideBtnBottom
      // isShowClose
      isShowHeader={isShowHeader}
      className="booking-profile">
    <>
      {isLoading && <CircularProgress />}
      {!isLoading && isShowConfirm &&
        <ConfirmBooking
          confirmMessage={confirmMessage}
          onBook={onRedirectPage}
          onCancel={() => setIsShowConfirm(false)}
        />
      }
      {!isLoading && !isShowConfirm && <div className="booking">
        {isTablet && (
          <div className="booking__back">
            <button type='button' onClick={onCloseBooking} className='btn-back'>
              <ArrowLeft />
              Back
            </button>
          </div>
        )}
        <div className={`booking-header ${module && isTablet && 'has-icon-module'}`}>
          <div className={"booking-title" + (isPathway? " is-pathway": "")} style={{ borderLeft: `6px solid ${bookingWindowData.theme_object?.pathway_color}` }}>
            {bookingWindowData.theme_object && (
              <p className="booking-text">
                <span className="cate" style={{backgroundColor: categoryBackgroundColor, color: categoryColor}}>{bookingWindowData.theme_object?.product_category_name}</span>
                <span className="cate" style={{backgroundColor: typeBackgroundColor, color: typeColor}}>{bookingWindowData.theme_object?.product_type_name}</span>
              </p>
            )}
            <div className={"lesson-title-container" + (isLessonTaken? " lesson-taken": "")}>
              <h2>{ReactHtmlParser(bookingWindowData.title)}</h2>
              {isLessonTaken && <Check />}
            </div>
            <div className="lesson-aim">{ReactHtmlParser(bookingWindowData.type === "activity"? bookingWindowData.sessionName: bookingWindowData.aim)}</div>
            <p className="booking-subtitle">
              <img src={clockIcon} alt="Time" />
              <span>{bookingWindowData.date}</span>
            </p>
            {bookingWindowData.locationIcon !== 'online' &&
              <p className="booking-text">
                <img src={mapPinIcon} alt="Location" />
                <span>{bookingWindowData.location}</span>
              </p>
            }
            {bookingWindowData.locationIcon === 'online' &&
            <p className="booking-text">
              <img src={monitorIcon} alt="Online class"/>
              <span>Online class</span>
            </p>
            }
            {module && isTablet && <Layers className="booking-header__icon" />}
          </div>
          
          {module && !isTablet &&
            <div className="booking-pathway">
              <div className="booking-pathway__text">
                <Layers strokeWidth="1" className="booking-pathway__module"/>
              </div>
              <p className="booking-pathway__subtext">Part of module</p>
              <div className="booking-pathway__text">{module.title}</div>
              {module.progress_check && <div className="booking-pathway__progress">
                <BookingProgressBar
                  total={parseInt(module.progress_check.required_lesson || 0)}
                  completed={parseInt(module.progress_check.learned_lesson > module.progress_check.required_lesson ? module.progress_check.required_lesson : module.progress_check.learned_lesson) || 0}
                  // inProgress={parseInt(module.progress_check.progress_score) || 1}
                  width="30px"
                  height="24px"
                  label="Progress"
                  colorActive="#5DEB4B"
                  colorDefault="#C8C8C8"
                  colorProgress="#23085A"/>
              </div>}
            </div>
          }
          {!module && isPathway && <div className="booking-pathway">
            {/* <div className="booking-pathway__text">{bookingWindowData.pathway}</div> */}
            {module ? <div className="booking-pathway__text">
              <Layers strokeWidth="1" className="booking-pathway__module"/>
            </div> : ''}
            <p className="booking-pathway__subtext">Part of module</p>
            <div className="booking-pathway__text">{bookingWindowData.theme}</div>
            {parseInt(bookingWindowData.themeTotal) > 0 && <div className="booking-pathway__progress">
              {/* <ProgressBarPathway
                label=""
                total={parseInt(bookingWindowData.themeTotal)}
                completed={parseInt(bookingWindowData.themeCompleted) || 0}
                inProgress={parseInt(bookingWindowData.themeInProgress) || 0}
                width="40px"
                height="16px"
                colorActive="#00EDC3"
                colorDefault="#23085A"
                colorProgress="#99EDD1"/> */}
              <BookingProgressBar
                total={parseInt(bookingWindowData.themeTotal)}
                completed={parseInt(bookingWindowData.themeCompleted > bookingWindowData.themeTotal ? bookingWindowData.themeTotal : bookingWindowData.themeCompleted) || 0}
                inProgress={parseInt(bookingWindowData.themeInProgress) || 0}
                width="30px"
                height="24px"
                label="Progress"
                colorActive="#5DEB4B"
                colorDefault="#C8C8C8"
                colorProgress="#23085A"/>
            </div>}
          </div>}
          {newIcon == 1 ? <div className='module-view-item__iconnew'>
            <img className="icon-new" src={Iconnews} alt="New Icon" />
          </div>  : "" }
        </div>
        <div className="line-grey"></div>
        {bookingListEnabled.map((item, index) => {
          return <div key={item.id} className="accordion-wrapper">
              <Accordion data={item} onChangeAccordion={onChangeAccordion}/>
              {!isMobile && index < bookingListEnabled.length - 1 && <div className="line-grey"></div>}
            </div>
        })}
        <div className="booking-btn">
          {/* 
          {bookingIsClosed ? (
            <div className="closed-booking-messenger">
              <img src={alertCircleIcon} alt="Alert" />
              This is a closed booking and cannot be cancelled.
            </div>
          ): ''}
          {(!bookingIsClosed) ?
            <div className="booking-btn__alert">
              <div>

                { msgError && (
                  <p className="alert-message alert-message--red">
                    <AlertCircle />
                    {ReactHtmlParser(msgError)}
                  </p> 
                )}
                {module && !bookingWindowData.enable_class_booking && bookingWindowData.enable_module_booking && !isShowBtnCancel ?
                    <p className="alert-message alert-message--orange">
                      <AlertCircle />
                      This lesson can be booked only as part of a module
                    </p>
                : ''}
                {module && bookingWindowData.enable_class_booking && bookingWindowData.enable_module_booking && !isShowBtnCancel ?
                    <p className="alert-message alert-message--orange">
                      <AlertCircle />
                      This lesson is also included in a module that is available.
                    </p>
                : ''}
              </div>
              {module && bookingWindowData.enable_class_booking && bookingWindowData.enable_module_booking && !isShowBtnCancel ?
                <>
                  <button type='button' className='btn-view-module' onClick={() => handleOpenModuleDetails(module.id)}>
                    View module
                  </button>
                </>
              : ''}
            </div>
          : ''} 
          */}
          {bookingIsClosed ? (
            <div className="closed-booking-messenger">
              <img src={alertCircleIcon} alt="Alert" />
              This is a closed booking and cannot be cancelled.
            </div>
          ): ''}
          <div className="booking-btn__alert">
            <div>
              {(!bookingIsClosed && msgError) && (
                <p className="alert-message alert-message--red">
                  <AlertCircle />
                  {ReactHtmlParser(msgError)}
                </p>
              )}
              {module && !bookingWindowData.enable_class_booking && bookingWindowData.enable_module_booking && !isBooking ?
                <p className="alert-message alert-message--orange">
                  <AlertCircle />
                  This lesson can be booked only as part of a module.
                </p>
              : ''}
              {module && bookingWindowData.enable_class_booking && bookingWindowData.enable_module_booking && !isBooking ?
                <p className="alert-message alert-message--orange">
                  <AlertCircle />
                  This lesson is also included in a module that is available.
                </p>
              : ''}
              {module && isBooking && isBookedWithModule ?
                <p className="alert-message alert-message--orange">
                  <AlertCircle />
                  You have booked this lesson as part of a module.
                </p>
              : ''}
            </div>
            {module && bookingWindowData.enable_class_booking && bookingWindowData.enable_module_booking && !isBooking ?
              <>
                <button type='button' className='btn-view-module' onClick={() => handleOpenModuleDetails(module.id)}>
                  View module
                </button>
              </>
            : ''}
          </div>
          
          <div className="booking-btn__left">
            <Tooltip title={`This lesson costs ${credits} Credit${credits > 1? 's': ''}`}>
              <Button variant="contained" className="btn btn-return">Credits: {credits}</Button>
            </Tooltip>
            <span className="icon-set icon-block secondary">
              {isShowIconFriend &&
                <Tooltip title="At least one of your friends has booked this class">
                  <span>
                    <Users />
                  </span>
                </Tooltip>
              }
              {isShowIconLevel && <Level code={isShowIconLevel} />}
              {isShowIconWorkplace &&
                <Tooltip title="Lesson belongs to Workplace English pathway">
                  <span>
                    <Workplace />
                  </span>
                </Tooltip>
              }
              {isShowWishlistToggle &&
                <div className="wishlist-block">
                  <div className="border" />
                  <Tooltip title={isInWishlist? "Remove from wishlist": "Add to wishlist"} className="wishlist-toggle-wrapper">
                    <Button variant="contained" className={"wishlist-toggle" + (isInWishlist? " in-wishlist": "")} onClick={toggleWishlist}>
                      <Star />
                    </Button>
                  </Tooltip>
                </div>
              }
            </span>
          </div>
          {/* {!bookingIsClosed && (
            <div className="booking-btn__right">
              <>
                {
                  module && bookingWindowData.enable_module_booking && !bookingWindowData.enable_class_booking && !isShowBtnCancel && 
                  <Button 
                    variant="contained" 
                    className={`btn btn-return`}
                    onClick={() => handleOpenModuleDetails(module.id)}
                  >
                    View module
                  </Button>
                }
                {isShowBtnBook && bookingWindowData.enable_class_booking ? 
                  <Button 
                    variant="contained" 
                    className={`btn btn-return${isDisableBtnBook? ' btn-disable': ''}${isLessonTaken? ' lesson-taken': ''}`}
                    onClick={proceedBooking}
                    disabled={isDisableBtnBook}
                  >
                    {isLessonTaken? 'Book again': 'Book'}
                  </Button>
                : ''}

                {
                  showJoinNow &&
                    <span className="lesson-theme-booked btn-join-container">
                      <Button variant="contained" className="btn btn-return btn-join secondary" onClick={() => windowOpen(joinLink)}>{joinNowText}</Button>
                    </span>
                }

                {
                  isShowBtnCancel ? (
                    <Button
                      variant="contained"
                      className={`btn btn-return${isDisableBtnCancel? ' btn-disable': ''} btn-booking btn-cancel`}
                      onClick={onRedirectPage}
                      disabled={isDisableBtnCancel}
                    >
                    { isTablet ? 'Cancel': 'Cancel Booking' }
                    </Button>
                  )
                  : (
                    <div className="booking-cancalled">
                      { isBooking ? 'This booking cannot be cancelled' : ''}
                    </div>
                  )
                }
              </>
            </div>
          )} */}

          <div className="booking-btn__right">
            <>
              {
                module && bookingWindowData.enable_module_booking && !bookingWindowData.enable_class_booking && !isBooking ? 
                <Button 
                  variant="contained" 
                  className={`btn btn-return`}
                  onClick={() => handleOpenModuleDetails(module.id)}
                >
                  View module
                </Button>
              : ''}
              {
                module && isBooking && isBookedWithModule ? 
                <Button 
                  variant="contained" 
                  className={`btn btn-return`}
                  onClick={() => handleOpenModuleDetails(module.id)}
                >
                  View module
                </Button>
              : ''}
              {isShowBtnBook && bookingWindowData.enable_class_booking ? 
                <Button 
                  variant="contained" 
                  className={`btn btn-return${isDisableBtnBook? ' btn-disable': ''}${isLessonTaken? ' lesson-taken': ''}`}
                  onClick={proceedBooking}
                  disabled={isDisableBtnBook}
                >
                  {isLessonTaken? 'Book again': 'Book'}
                </Button>
              : ''}

              {
                showJoinNow &&
                  <span className="lesson-theme-booked btn-join-container">
                    <Button variant="contained" className="btn btn-return btn-join secondary" onClick={() => windowOpen(joinLink)}>{joinNowText}</Button>
                  </span>
              }

              {
                isShowBtnCancel && !isBookedWithModule ? (
                  <Button
                    variant="contained"
                    className={`btn btn-return${isDisableBtnCancel? ' btn-disable': ''} btn-booking btn-cancel`}
                    onClick={onRedirectPage}
                    disabled={isDisableBtnCancel}
                  >
                    Cancel
                  </Button>
                )
                : (
                  <div className="booking-cancalled">
                    { isBooking && !isBookedWithModule ? 'This booking cannot be cancelled' : ''}
                  </div>
                )
              }
            </>
          </div>
        </div>
        <div className="booking-btn">
          <div className="booking-btn__left">
            {isLessonTaken &&
              <div className="lesson-taken">
                <span>You’ve already taken this lesson</span>
                <div className="bar" />
              </div>
            }
          </div>
        </div>
      </div>}
    </>
    </ProfileWraper>
  )
}

const ConfirmBooking = ({confirmMessage, onBook, onCancel}) => (
  <>
    <div className="cancel-booking">
      <h2>Are you sure you want to continue this booking?</h2>
      <div className="cancel-missing">
        <img src={iconMissing} alt="Alert" />
        <div>
          <p>{ReactHtmlParser(confirmMessage)}</p>
        </div>
      </div>
      <div className="btn-block">
        <Button variant="contained" className="btn btn-return" onClick={onCancel}>No, I don't want to continue</Button>
        <Button variant="contained" className="btn btn-cancel" onClick={onBook}>Continue Booking</Button>
      </div>
    </div>
  </>
);

export default memo(BookingPage);