import { useState } from 'react';
import { Button, Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import CalendarViewItem from './CalendarViewItem';
import PassClassViewItem from './PassClassViewItem';
import CircularProgress from '../../Common/CircularProgress';

import '../../../../../sass/private/CalendarPage/CalendarListView.scss';

import arrowIcon from '../../../../../assets/image/arrow.png';

const CalendarListView = (props) => {
  const [isChecked, setIsChecked] = useState(false);
  const { title, isHideIconArrow, data, dataCalendarViewFull, dataCalendarViewAvailable, pastclasses, onNextPrevWeekList, onRedirectBookingWidow, showNoResultsMsg, showMorePastClass, isLoading, isSchedule, dataPassClass, isLoadingSchedule, isLoadingPassClasses, landingData, onClickMenu } = props;
  const hasResults = Array.isArray(data) && data.length > 0;
  const hasPastClass = Array.isArray(dataPassClass) && dataPassClass.length > 0;
  const dataCalendarViewPast = dataCalendarViewAvailable?.filter(item => {
    return item.isPast;
  });
  const dataCalendarViewAvailableNow = dataCalendarViewAvailable?.filter(item => {
    return item.isPast === false;
  });
  const content = hasResults && data.map((item, index) => {
    item = item || {};

    return (pastclasses ? 
      <PassClassViewItem
        key={index}
        typeLesson={item.productType}
        title={item.title}
        desc={item.infoLines?.title}
        colorHex={item.colorHex}
        isMissed={item.isMissed}
        defaultLessonParam={item.defaultLessonParam}
        teacher={item.infoLines?.centreTeacherName}
        friendIcon = {item.iconSet?.friend?.showIcon}
        levelsIcon={item.otherLevel}
        wishListIcon={item.iconSet?.wishList?.showIcon}
        workPlaceIcon={item.iconSet?.workPlace?.showIcon}
        actionDetails={item.actionArea?.showLinkDetailPage}
        actionAudio={item.actionArea?.showIconAudio}
        actionRedIcon={item.actionArea?.showRedIcon}
        actionText={item.actionArea?.showText}
        module={item.module}
        theme={item.theme_object}
      /> :
      <CalendarViewItem
        key={index}
        title={item.productType}
        desc={item.title}
        time={item.infoLines?.title}
        active={item.booked}
        borderColor={item.colorHex}
        isPastEndTime={item.isPastEndTime || false}
        lessonTimeId={item.lessonTimeId}
        onRedirectBookingWidow={onRedirectBookingWidow}
        teacher={item.infoLines?.centreTeacherName}
        friendIcon = {item.iconSet?.friend?.showIcon}
        levelsIcon={item.otherLevel}
        wishListIcon={item.iconSet?.wishList?.showIcon}
        workPlaceIcon={item.iconSet?.workPlace?.showIcon}
        showJoinNow={item.actionArea?.showButtonJoinClass}
        joinLink={item.actionArea?.linkJoinClass}
        joinNowText={item.actionArea?.textJoinClassForLessonBlock}
        ticked={item.ticked}
        isLocked={item.isLocked}
        availabilityText={item.infoLines?.availability}
        showAlertIcon={item.infoLines?.fullWarningIcon}
        module={item.module}
        theme={item.theme_object}
        themeBook={item.is_booked_with_module}
      />
    )
  })
  const pastContent = hasPastClass && dataPassClass.map((item, index) => {
    item = item || {};

    return (
      <PassClassViewItem
        key={index}
        typeLesson={item.productType}
        title={item.title}
        desc={item.infoLines?.title}
        colorHex={item.colorHex}
        isMissed={item.isMissed}
        defaultLessonParam={item.defaultLessonParam}
        teacher={item.infoLines?.centreTeacherName}
        friendIcon = {item.iconSet?.friend?.showIcon}
        levelsIcon={item.otherLevel}
        wishListIcon={item.iconSet?.wishList?.showIcon}
        workPlaceIcon={item.iconSet?.workPlace?.showIcon}
        actionDetails={item.actionArea?.showLinkDetailPage}
        actionAudio={item.actionArea?.showIconAudio}
        actionRedIcon={item.actionArea?.showRedIcon}
        actionText={item.actionArea?.showText}
        module={item.module}
        theme={item.theme_object}
      />)
  });
  
  return (
    <div className="calendar-view">
      {!isSchedule && (
        <div className="calendar-view__header">
          <>
            <div className="title">
              {dataCalendarViewAvailableNow?.length > 0 ? 'Available' : ''}
            </div>
            <div className="days">{title}</div>
          </>
          {!isHideIconArrow && <div className="btn-block">
            <img
              className="btn-prev"
              src={arrowIcon}
              alt="Previous day"
              tabIndex={0}
              onClick={() => onNextPrevWeekList('prev')}
              onKeyPress={() => onNextPrevWeekList('prev')}
            />
            <img
              src={arrowIcon}
              alt="Next day"
              tabIndex={0}
              onClick={() => onNextPrevWeekList('next')}
              onKeyPress={() => onNextPrevWeekList('next')}
            />
          </div>}
        </div>
      )}
      {isSchedule ? (
        <>
          <div className="calendar-view__header calendar-view__header--schedule">
            <div className="title">Your booked classes ({landingData?.bookedClassNumber || 0})</div>
          </div>
          {isLoadingSchedule ? <CircularProgress />:
            <>
              {hasResults ?
                <div className='schedule-list'>
                  {content}
                </div>
              :
                <div className='schedule-list-no-result'>
                  <p className="body-text schedule-list-alert">You have not got any classes booked.</p>
                  {landingData?.module_tab?.show_module_tab && 
                    <Button variant="contained" className="btn btn-book" onClick={() => onClickMenu(3)}>+ Book a module</Button>
                  }
                  <Button variant="contained" className="btn btn-book" onClick={() => onClickMenu(1)}>+ Book a class</Button>
                </div>
              }
            </>
          }
          <>
            <div className="calendar-view__header calendar-view__header--schedule">
              <div className="title">Your past classes ({landingData?.pastClassesNumber || 0})</div>
              <span className='expand-past-class' onClick={() => {setIsChecked((prev) => !prev)}}>
                {isChecked ? 'Hide classes' : 'Show classes'} 
                {isChecked ? <ExpandLess/> : <ExpandMore/>}
              </span>
            </div>
            
            <Collapse className='past-class-mobile' in={isChecked}>
              {hasPastClass &&
                <div className='schedule-list'>
                  {pastContent}
                  {!isLoadingPassClasses && showMorePastClass &&
                    <div className="btn-show-more-container">
                      <Button variant="contained" className="btn btn-show-more" onClick={() => showMorePastClass()}>Load more</Button>
                    </div>
                  }
                </div>
              }
              {!isLoadingPassClasses && !hasPastClass && <p className="body-text schedule-list-message">You have not attended any classes yet.</p>} 
              {isLoadingPassClasses && <CircularProgress style={{marginTop: '20px'}} />}
            </Collapse>
             
          </>
        </>
      ) : (
        <>
          {(hasResults && !dataCalendarViewAvailable) ?
            <div>
              {content}
            </div>
          : ''}
          <div>
            {dataCalendarViewAvailableNow?.length > 0 ? (
              dataCalendarViewAvailableNow.map((item, index) => {
                return (
                  <CalendarViewItem
                    key={index}
                    title={item.productType}
                    desc={item.title}
                    time={item.infoLines?.title}
                    active={item.booked}
                    borderColor={item.colorHex}
                    isPastEndTime={item.isPastEndTime || false}
                    lessonTimeId={item.lessonTimeId}
                    onRedirectBookingWidow={onRedirectBookingWidow}
                    teacher={item.infoLines?.centreTeacherName}
                    friendIcon = {item.iconSet?.friend?.showIcon}
                    levelsIcon={item.otherLevel}
                    wishListIcon={item.iconSet?.wishList?.showIcon}
                    workPlaceIcon={item.iconSet?.workPlace?.showIcon}
                    showJoinNow={item.actionArea?.showButtonJoinClass}
                    joinLink={item.actionArea?.linkJoinClass}
                    joinNowText={item.actionArea?.textJoinClassForLessonBlock}
                    ticked={item.ticked}
                    isLocked={item.isLocked}
                    availabilityText={item.infoLines?.availability}
                    showAlertIcon={item.infoLines?.fullWarningIcon}
                    module={item.module}
                    theme={item.theme_object}
                  />
                )
              })
            ) : ''}
          </div>
          {dataCalendarViewFull?.length > 0 ? (
            <>
              <div className="calendar-view__header calendar-fully-booked">
                <div className="title">Fully booked</div>
              </div>
              <div>
                {dataCalendarViewFull.map((item, index) => {
                  return (
                    <CalendarViewItem
                      key={index}
                      title={item.productType}
                      desc={item.title}
                      time={item.infoLines?.title}
                      active={item.booked}
                      borderColor={item.colorHex}
                      isPastEndTime={item.isPastEndTime || false}
                      lessonTimeId={item.lessonTimeId}
                      onRedirectBookingWidow={onRedirectBookingWidow}
                      teacher={item.infoLines?.centreTeacherName}
                      friendIcon = {item.iconSet?.friend?.showIcon}
                      levelsIcon={item.otherLevel}
                      wishListIcon={item.iconSet?.wishList?.showIcon}
                      workPlaceIcon={item.iconSet?.workPlace?.showIcon}
                      showJoinNow={item.actionArea?.showButtonJoinClass}
                      joinLink={item.actionArea?.linkJoinClass}
                      joinNowText={item.actionArea?.textJoinClassForLessonBlock}
                      ticked={item.ticked}
                      isLocked={item.isLocked}
                      availabilityText={item.infoLines?.availability}
                      showAlertIcon={item.infoLines?.fullWarningIcon}
                      classes='full'
                      isFull='1'
                      module={item.module}
                      theme={item.theme_objectct}
                    />
                  )
                })}
              </div>
            </>
          ) : ''}          
          {dataCalendarViewPast?.length > 0 ? (
            <>
              <div className="calendar-view__header calendar-fully-booked">
                <div className="title">Past classes</div>
              </div>
              <div>
                {dataCalendarViewPast.map((item, index) => {
                  return (
                    <CalendarViewItem
                      key={index}
                      title={item.productType}
                      desc={item.title}
                      time={item.infoLines?.title}
                      active={item.booked}
                      borderColor={item.colorHex}
                      isPastEndTime={true}
                      lessonTimeId={item.lessonTimeId}
                      onRedirectBookingWidow={onRedirectBookingWidow}
                      teacher={item.infoLines?.centreTeacherName}
                      friendIcon = {item.iconSet?.friend?.showIcon}
                      levelsIcon={item.otherLevel}
                      wishListIcon={item.iconSet?.wishList?.showIcon}
                      workPlaceIcon={item.iconSet?.workPlace?.showIcon}
                      showJoinNow={item.actionArea?.showButtonJoinClass}
                      joinLink={item.actionArea?.linkJoinClass}
                      joinNowText={item.actionArea?.textJoinClassForLessonBlock}
                      ticked={item.ticked}
                      isLocked={item.isLocked}
                      availabilityText={item.infoLines?.availability}
                      showAlertIcon={item.infoLines?.fullWarningIcon}
                      module={item.module}
                      theme={item.theme_object}
                    />
                  )
                })}
              </div>
            </>
          ) : ''}          
        </>
      )}
      
      {isLoading && <CircularProgress />}
      {showNoResultsMsg && !hasResults &&
        <div className="calendar-no-results">
          <p>No results found</p>
          <p>Try changing the filters to find what you’re looking for.</p>
        </div>
      }
    </div>
  )
}

export default CalendarListView;