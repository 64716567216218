import { memo, useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import ReactHtmlParser from "react-html-parser";
import { Skeleton } from "@mui/material";

import "../../../../sass/private/Module/ModuleBookingMobile.scss";
import Calendar from "../../../components/icon/Calendar";
import MapPin from "../../../components/icon/MapPin";
import User from "../../../components/icon/User";
import Key from "../../../components/icon/Key";
import Writing from "../../../components/icon/Writing";
import Users from "../../../components/icon/Users";
import ArrowDown from "../../../components/icon/ArrowDown";
import Check from "../../../components/icon/Check";
import AlertCircle from "../../../components/icon/AlertCircle";

import ModuleBooked from "./ModuleBooked";
import AlertTriangle from "../../../components/icon/AlertTriangle";
import CircularProgressBar from "../Common/CircularProgressBar";
import Repeat from "../../../components/icon/Repeat";
import ArrowLeft from "../../../components/icon/ArrowLeft";
import Clock from "../../../components/icon/Clock";
import Iconnews from "../../../../assets/image/new-icon.png";
import Modal from "../Dialog/Modal";
import CustomCircularProgress from "../Common/CircularProgress";

import useModule from "../../../hooks/HomePage/useModule";
const ModuleBooking = (props) => {
  const { id, handleOpenPracticeModal, lessonType, buttonTitle } = props;
  const [active, setActive] = useState(null);
  const [progressUrl, setProgressUrl] = useState(null);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [isOpenProgressDetail, setIsOpenProgressDetail] = useState(false);

  const {
    isLoading,
    isLoadingValidateModule,
    moduleDetail,
    validateModule,
    getModuleDetail,
    handleBookModule,
    handleContinueBookModule,
    totalCredit,

    isShowBooked,
    isShowCancel,
    isShowConfirm,

    moduleListLesson,
    toggleBookAgain,
    bookedDetail,

    handleCancelModule,
    handleContinueCancelModule,
    isCanceled,
    cancelError,
    validateCancelModule,
    dataDetailReloadTokenSelf,
    isLoadingBooking
  } = useModule(props.reloadData);

  useEffect(() => {
    getModuleDetail(id);
  }, []);
  
  useEffect(() => {
    if (dataDetailReloadTokenSelf) {
      getModuleDetail(id);
    }
  }, [dataDetailReloadTokenSelf]);
  
  useEffect(() => {
    if (isLoading === false) {
      if (document.querySelector('.mobile-menu-tabs')) {
        window.scroll({
          top: document.querySelector('.mobile-menu-tabs').offsetTop,
          behavior: "smooth",
        });
      }
    }
  }, [isLoading]);

  const handleToggle = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };
  const handleShare = (url) => {
    window.open(url, "_blank");
  }

  const handleOpenModuleModal = () => {
    setIsOpenPopup(true);
    handleBookModule(id);
  };
  const handleOpenCancelModal = () => {
    setIsOpenPopup(true);
    handleCancelModule(id);
  };
  const handleCloseModuleModal = () => {
    setIsOpenPopup(false);
  };
  const handleOpenProgressModal = (url) => {
    setProgressUrl(url);
    setIsOpenProgressDetail(true);
  };
  const handleCloseProgressModal = () => {
    setIsOpenProgressDetail(false);
    getModuleDetail(id);
  };
  

  let messageType = "";
  let messageString = "";
  if (moduleDetail?.available_space <= 0) {
    messageType = "module-message--red";
    messageString = "Full";
  }
  if (moduleDetail?.available_space > 0 && moduleDetail?.available_space < 4) {
    messageType = "module-message--orange";
    messageString = `Only ${moduleDetail?.available_space} space${moduleDetail?.available_space > 1 ? "s" : ""} left`;
  }
  if (moduleDetail?.available_space > 3) {
    messageType = "module-message--default";
    messageString = `${moduleDetail?.available_space} Spaces left`;
  }
  
  
  const ProgressCheck = ({ progressCheckState }) => {
    switch (progressCheckState) {
      case 0:
        return (
          <div className="plan-item plan-item--last">
            <div className="plan-item__number">
              <div className="plan-item__number-value">
                <Writing className="plan-item__number-icon" />
                <div className="sub-icon">
                  <Key />
                </div>
              </div>
            </div>
            <div
              className="plan-item__right plan-item__right--last"
              style={{ borderLeftColor: moduleDetail?.product_type_color }}
            >
              <div className="plan-item__info">
                <ul className="plan-item__category module-category">
                  <li>
                    <span
                      className="cate cate--type-speaking"
                      style={{ backgroundColor: moduleDetail?.product_type_color }}
                    >
                      {moduleDetail?.product_type_name}
                    </span>
                  </li>
                </ul>
                <h3 className="plan-item__title">Module progress check</h3>
                <p className="plan-item__desc">{moduleDetail?.progress_check?.hint}</p>
              </div>
              <div className="plan-item__score">
                <Key />
              </div>
            </div>
          </div>
        );
      case 1:
      case 2:
        return (
          <div className="plan-item plan-item--last">
            <div className="plan-item__number">
              <div className="plan-item__number-value unlocked">
                <Writing className="plan-item__number-icon" />
                <div className="sub-icon checked">
                  <Repeat />
                </div>
              </div>
            </div>
            <div
              className="plan-item__right plan-item__right--last"
              style={{ borderLeftColor: moduleDetail?.pathway_color }}
            >
              <div className="plan-item__info">
                <ul className="plan-item__category module-category">
                  <li>
                    <span
                      className="cate cate--type-speaking"
                      style={{ backgroundColor: moduleDetail?.product_type_color }}
                    >
                      {moduleDetail?.product_type_name}
                    </span>
                  </li>
                </ul>
                <h3 className="plan-item__title">Module progress check</h3>
                <p className="plan-item__desc">{moduleDetail?.progress_check?.hint}</p>
              </div>
              <div className="plan-item__right__foot">
                <div className="plan-item__progress">
                  <CircularProgressBar
                    strokeWidth="3"
                    sqSize="52"
                    completeProgress={moduleDetail?.progress_check.progress_score}
                    percentage={100 - moduleDetail?.progress_check.progress_score * 10}
                  />
                </div>
                <div className="plan-item__action">
                  <Button
                    variant="contained"
                    className="btn btn-book"
                    onClick={() => handleOpenProgressModal(moduleDetail?.progress_check?.button_url)}
                  >
                    {moduleDetail?.progress_check?.button_text}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="plan-item plan-item--last">
            <div className="plan-item__number">
              <div className="plan-item__number-value completed">
                <Writing className="plan-item__number-icon" />
                <div className="sub-icon checked">
                  <Check />
                </div>
              </div>
            </div>
            <div
              className="plan-item__right plan-item__right--last"
              style={{ borderLeftColor: moduleDetail?.pathway_color }}
            >
              <div className="plan-item__info">
                <ul className="plan-item__category module-category">
                  <li>
                    <span
                      className="cate cate--type-speaking"
                      style={{ backgroundColor: moduleDetail?.product_type_color }}
                    >
                      {moduleDetail?.product_type_name}
                    </span>
                  </li>
                </ul>
                <h3 className="plan-item__title">Module progress check</h3>
                <p className="plan-item__desc">{moduleDetail?.progress_check?.hint}</p>
              </div>
              <div className="plan-item__right__foot">
                <div className="plan-item__progress">
                  <CircularProgressBar
                    strokeWidth="3"
                    sqSize="52"
                    completeProgress={0}
                    percentage={0}
                    text="?"
                  />
                </div>
                <div className="plan-item__action">
                  <Button variant="contained" className="btn btn-book" disabled>Wait...</Button>
                </div>
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="plan-item plan-item--last">
            <div className="plan-item__number">
              <div className="plan-item__number-value completed">
                <Writing className="plan-item__number-icon" />
                <div className="sub-icon checked">
                  <Check />
                </div>
              </div>
            </div>
            <div
              className="plan-item__right plan-item__right--last"
              style={{ borderLeftColor: moduleDetail?.pathway_color }}
            >
              <div className="plan-item__info">
                <ul className="plan-item__category module-category">
                  <li>
                    <span
                      className="cate cate--type-speaking"
                      style={{ backgroundColor: moduleDetail?.product_type_color }}
                    >
                      {moduleDetail?.product_type_name}
                    </span>
                  </li>
                </ul>
                <h3 className="plan-item__title">Module progress check</h3>
                <p className="plan-item__desc">{moduleDetail?.progress_check?.hint}</p>
              </div>
              <div className="plan-item__right__foot">
                <div className="plan-item__progress">
                  <CircularProgressBar
                    strokeWidth="3"
                    sqSize="52"
                    completeProgress={moduleDetail?.progress_check.progress_score}
                    percentage={100 - moduleDetail?.progress_check.progress_score * 10}
                  />
                </div>
                <div className="plan-item__action">
                  {moduleDetail?.progress_check?.button_url && 
                    <Button variant="contained" className="btn btn-book" onClick={() => handleShare(moduleDetail?.progress_check?.button_url)}>Share</Button>
                  }
                  {moduleDetail?.progress_check?.review_activity_url &&
                    <span className='btn-link' onClick={() => handleOpenProgressModal(moduleDetail?.progress_check?.review_activity_url)}>Review activities</span>
                  }
                  
                </div>
              </div>
            </div>
          </div>
        );

      default:
        return (
          <div className="plan-item plan-item--last">
            <div className="plan-item__number">
              <div className="plan-item__number-value">
                <Writing className="plan-item__number-icon" />
                <div className="sub-icon">
                  <Key />
                </div>
              </div>
            </div>
            <div
              className="plan-item__right plan-item__right--last"
              style={{ borderLeftColor: moduleDetail?.product_type_color }}
            >
              <div className="plan-item__info">
                <ul className="plan-item__category module-category">
                  <li>
                    <span
                      className="cate cate--type-speaking"
                      style={{ backgroundColor: moduleDetail?.product_type_color }}
                    >
                      {moduleDetail?.product_type_name}
                    </span>
                  </li>
                </ul>
                <h3 className="plan-item__title">Module progress check</h3>
                <p className="plan-item__desc">{moduleDetail?.progress_check?.hint}</p>
              </div>
              <div className="plan-item__score">
                <Key />
              </div>
            </div>
          </div>
        );
    }
  };
  // handle display error
  let messageListOrange;
  let messageListRed;
  if (validateModule) {
    const moduleValidate = validateModule;
    if (moduleValidate.warnings) {
      const warningListOrange = Object.keys(moduleValidate.warnings).filter(function(item){
        return moduleValidate.warnings[item].color_type === 'orange'
      })
      const warningListRed = Object.keys(moduleValidate.warnings).filter(function(item){
        return moduleValidate.warnings[item].color_type === 'red'
      })
      messageListOrange = warningListOrange.map(item => {
        return moduleValidate.warnings[item];
      }) 
      messageListRed = warningListRed.map(item => {
        return moduleValidate.warnings[item];
      }) 
    }
  }
  const cateColor = moduleDetail?.theme_object?.left_font_color;
  const typeColor = moduleDetail?.theme_object?.right_font_color;
  const newIcon = moduleDetail?.theme_object?.is_new;
  return (
    <>
      {isLoading ? (
        <CustomCircularProgress style={{marginTop: '50px'}}></CustomCircularProgress>
      ) : ''}
      {!isLoading ? (
        <div className="module-booking-mobile">
          <Button variant="contained" className="btn-back" onClick={() => props.handleCloseDetails()}>
            <ArrowLeft />
            Back
          </Button>
          <div className="module-booking-mobile__container">
            <div className="top-block" style={{ borderLeftColor: moduleDetail?.pathway_color }}>
              <div className="top-block__img">
                <img src={moduleDetail?.image} alt={moduleDetail?.title} />
                {newIcon == 1 ? <div className='module-view-item__iconnew'>
                  <img className="icon-new icon-new-mb" src={Iconnews} alt="New Icon" />
                </div>  : "" }
              </div>
              <div className="top-block__info">
                <ul className="top-block__category">
                  <li>
                    <span
                      className="cate cate--lesson-social"
                      style={{ backgroundColor: moduleDetail?.theme_object?.product_category_color, color: cateColor }}
                    >
                      {moduleDetail?.product_category_name}
                    </span>
                  </li>
                  <li>
                    <span
                      className="cate cate--type-speaking"
                      style={{ backgroundColor: moduleDetail?.theme_object?.product_type_color, color: typeColor }}
                    >
                      {moduleDetail?.product_type_name}
                    </span>
                  </li>
                </ul>
                <h3 className="top-block__title">{moduleDetail?.title}</h3>
                <ul className="top-block__list">
                  <li className="top-block__date">
                    <Calendar className="top-block__icon" />
                    {moduleDetail?.start_date} - {moduleDetail?.end_date}
                  </li>
                  <li className="top-block__location">
                    <MapPin className="top-block__icon" />
                    {moduleDetail?.centre_name}
                  </li>
                  {Object.keys(moduleDetail?.teacher_names).length > 0 &&
                    <li className='top-block__location'>
                      {Object.keys(moduleDetail.teacher_names).length === 1 ? <User className="top-block__icon" /> : <Users className="top-block__icon" />}
                      {Object.keys(moduleDetail.teacher_names).length === 1 ? moduleDetail.teacher_names[Object.keys(moduleDetail.teacher_names)[0]] : <span className="teacher-detail">See below for teacher details</span>}
                    </li>
                  }
                </ul>
              </div>
            </div>
            <div className="content-block">
              <div className="content-block-item">
                <h3 className="block-title">About</h3>
                <div className="block-desc">
                  {ReactHtmlParser(moduleDetail?.theme_description)}
                  <br/>
                  <br/>
                  {moduleDetail?.progress_check &&
                    <>
                      <p>Attend all {moduleDetail?.progress_check?.required_lesson} {moduleDetail?.progress_check?.required_lesson > 1 ? 'lessons':'lesson'} in this module to unlock the progress check.</p>
                      <p>Achieve a score of 70% or higher to earn your badge and certificate.</p>
                    </>
                  }
                </div>
              </div>
              <div className="content-block-item planner-block">
                <h3 className="block-title">Planner</h3>
                {moduleListLesson?.length > 0 && moduleListLesson.map((item, index) => {
                  const { is_delivered, is_learned_lesson, is_next_class, is_absent, is_unbooked_missed, info_type, info_message, is_missed, available_space} = item;
                  return (
                    <div className="plan-item" key={item.id}>
                      <div className="plan-item__number">
                        <div
                          className={`plan-item__number-value 
                            ${is_absent ? 'missed' : ''}
                            ${is_unbooked_missed ? 'unbooked-missed' : ''}
                            ${is_delivered ? "completed" : ""} 
                            ${is_next_class ? "unlocked" : ""}
                          `}
                        >
                          {index + 1}
                          {!is_absent && is_learned_lesson && (
                            <div className="sub-icon checked">
                              <Check />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="plan-item__right">
                        <div className="plan-item__info">
                          <h3 className="plan-item__title">
                            {ReactHtmlParser(item.title)}
                          </h3>
                          <ul className="plan-item__list">
                            <li className="plan-item__date">
                              <Clock className="plan-item__icon" />
                              {item.lesson_time}
                            </li>
                            <li className="plan-item__location">
                              <MapPin className="plan-item__icon" />
                              {item.centreName}, {item.classroom}
                            </li>
                            {Object.keys(moduleDetail?.teacher_names).length > 1 && item?.teachers ? (
                              <li className="plan-item__location">
                                {item.teachers.length === 1 ?  <User className="plan-item__icon" /> : <Users className="plan-item__icon" />}
                                {item.teachers.map((its, index) => {
                                  return (
                                    <span key={its.id}>
                                      {its.name_en} {its.surname_en}
                                      {index < (item.teachers.length - 1) ? ', ' : ''}
                                    </span>
                                  )
                                })}
                              </li>
                            ) : ""}
                          </ul>
                        </div>
                        <div className="plan-item__control">
                          {!is_missed && available_space > 0 && !moduleDetail.is_booked && info_type ? 
                            <div className={`plan-item__warning ${info_type === 'optional' ? '' : 'plan-item__warning--red'}`}>
                              <p className={`module-message ${info_type === 'optional'  ? 'module-message--orange' : 'module-message--red'}`}>
                                <AlertCircle />
                                {info_message}
                              </p>
                              {info_type === 'optional' ?
                                <div className="form-checkbox">
                                  <label>
                                    <input type="checkbox" name="" checked={item.bookAgain} disabled={isLoadingValidateModule} onChange={()=> toggleBookAgain(item.id, !item.bookAgain, item.credit, id)} />
                                    <span></span>
                                  </label>
                                </div>
                              : ''}
                            </div>
                          : ''}
                          <button
                            type="button"
                            className={active === item.id ? "active" : ""}
                            onClick={() => handleToggle(item.id)}
                          >
                            {active === item.id ? "Hide" : "Show"} details
                            <ArrowDown />
                          </button>
                        </div>
                        <div className={`plan-item__details ${active === item.id ? "active" : ""}`}>
                          <div className="plan-item__details__block">
                            <h3 className="block-title">About this lesson</h3>
                            <div>
                              <div dangerouslySetInnerHTML={{ __html: item.description }}></div>
                            </div>
                          </div>
                          <div className="plan-item__details__block">
                            <h3 className="block-title">About the teacher</h3>
                            {item.teachers?.map((it) => {
                              return (
                                <div className="plan-item__teacher" key={it.id}>
                                  <div className="plan-item__teacher__img">
                                    {it.image_url &&
                                      <img src={it.image_url} alt="Teacher name" />
                                    }
                                  </div>
                                  <div className="plan-item__teacher__info">
                                    <h3 className="plan-item__teacher__name">
                                      {it.name_en} {it.surname_en}
                                    </h3>
                                    <p className="plan-item__teacher__desc">{ReactHtmlParser(it.desc_en)}</p>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className="plan-item__details__block">
                            <h3 className="block-title">Practice activities</h3>
                            <p>
                              These activities will help you review and practice the language you learned in the
                              lesson.
                            </p>
                            {/* <p className="module-message module-message--orange">
                              <AlertCircle />
                              Practice activities will become available after you attend the lesson.
                            </p>
                            <Button variant="contained" className="btn btn-book">
                              Practice
                            </Button> */}
                            {(moduleDetail?.progress_check?.learned_lesson) >= (moduleDetail?.progress_check?.required_lesson) ?  <Button variant="contained" className={`btn btn-booked btn-start btn-practice secondary btn-book`} onClick={() => handleOpenPracticeModal(id, lessonType)}>{buttonTitle ?? 'Practice'}</Button> : <p className="module-message module-message--orange">
                            <AlertCircle />
                            Practice activities will become available after you attend the lesson.
                          </p>}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {moduleDetail?.progress_check &&
                  <div className="plan-item--last-wrap ">
                    <ProgressCheck progressCheckState={moduleDetail?.progress_check.progress_state} />
                  </div>
                }
              </div>
              {moduleDetail.is_booked ? (
                <div className="content-block-foot">
                  <div className="content-block-foot__button">
                    <Button variant="contained" className="btn btn-cancel" onClick={() => handleOpenCancelModal()} disabled={!moduleDetail.is_show_module_cancel_button}>
                      Cancel module
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="content-block-foot">
                  {isLoadingValidateModule ? (
                    <>
                      <div className="content-block-foot__top">  
                        <Skeleton
                          animation="wave" 
                          variant="text" 
                          className="skeleton-credit"
                        />
                        <Skeleton 
                          animation="wave" 
                          variant="text" 
                          className="skeleton-available-status"
                        />
                      </div>
                      <div className="content-block-foot__button">
                        <Skeleton 
                          animation="wave" 
                          variant="text" 
                          className="skeleton-button"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="content-block-foot__top">
                        {totalCredit > 0 ? (
                          <p className="content-block-foot__credit module-credit">Credits: {totalCredit}</p>
                        ) : ''}
                        <p className={`module-message content-block-foot__status ${messageType}`}>
                          <Users />
                          {messageString}
                        </p>
                      </div>
                      {(validateModule?.errors) ? (
                        <div className={`content-block-foot__message module-message module-message--red`}>
                          <AlertCircle />
                          <div>
                            {Object.keys(validateModule.errors).map(item => (
                              ReactHtmlParser(validateModule.errors[item].message)
                            ))}
                          </div>
                        </div>
                      ) : ''}
                      <div className="content-block-foot__button">
                        <Button 
                          variant="contained" 
                          className="btn btn-book" 
                          onClick={() => handleOpenModuleModal()} 
                          disabled={!validateModule?.is_bookable || moduleDetail?.available_space === 0}
                        >
                          Book module
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      ) :''}
      <Modal
        open={isOpenPopup}
        className="module-booking-modal module-booking-modal-mobile"
        handleCloseDialog={handleCloseModuleModal}
      >
        <Button variant="contained" onClick={handleCloseModuleModal} className="btn btn-return btn-top">
          Close
        </Button>
        <div className="external-box">
          <div className="module-booking-modal-content">
            {isLoadingBooking && <CustomCircularProgress></CustomCircularProgress>}
            {!isLoadingBooking && !isShowBooked && isShowConfirm && 
              <ConfirmBooking 
                messageListOrange = {messageListOrange}
                messageListRed = {messageListRed} 
                onBook={() => handleContinueBookModule(id)} 
                onCancel={() => handleCloseModuleModal()} 
                totalCredit = {totalCredit}
              />
            }
            
            {!isLoadingBooking && !isShowBooked && isShowCancel && 
              <CancelBooking 
                message={validateCancelModule}
                onBook={() => handleContinueCancelModule(id)} 
                onCancel={() => handleCloseModuleModal()} 
                isCanceled={isCanceled}
                messageError={cancelError}
              />
            }
            {!isLoadingBooking && isShowBooked && 
              <ModuleBooked 
                title={bookedDetail?.title} 
                startDate={bookedDetail?.start_date} 
                endDate={bookedDetail?.end_date} 
                centreName={bookedDetail?.centre_name} 
                pathwayColor={bookedDetail?.pathway_color} 
                productCategoryColor={bookedDetail?.product_category_color} 
                productColorLeft={bookedDetail?.theme_object?.left_font_color}
                productTypeColor={bookedDetail?.theme_object?.right_font_color}
                productCategory={ bookedDetail?.product_category_name}
                newIcon = {bookedDetail?.theme_object?.is_new}
                productType={bookedDetail?.product_type_name} 
                googleCalendar={bookedDetail?.google_calendar} 
                officeCalendar={bookedDetail?.office_calendar} 
                messageError={bookedDetail?.message} 
              />
            }
          </div>
        </div>
      </Modal>
      <Modal
        open={isOpenProgressDetail}
        handleCloseDialog={handleCloseProgressModal}
        className="module-progress-modal"
      >
        <Button variant="contained" onClick={handleCloseProgressModal} className="btn btn-return btn-top">
          Close
        </Button>
        <div className="external-box">
          <iframe src={progressUrl} width="100%" height="100%" title="Progress" />
        </div>
      </Modal>
    </>
  );
};
const ConfirmBooking = ({  messageListRed, messageListOrange, onBook, onCancel, totalCredit }) => (
  <>
    <div className="cancel-booking">
      <h2>Are you sure you want to continue this booking?</h2>
      {(messageListRed && messageListRed.length) ? (
        <div className="cancel-missing">
          <AlertCircle />
          <div className="cancel-missing__content">
            {messageListRed.map(item => ReactHtmlParser(item.message))}
          </div>
        </div>
      ) : ''}
      {(messageListOrange && messageListOrange.length) ? (
        <div className="cancel-missing cancel-missing--orange">
          <AlertCircle />
          <div className="cancel-missing__content">
            {messageListOrange.map(item => ReactHtmlParser(item.message))}
          </div>
        </div>
      ): ''}
      <div className="btn-block">
        { totalCredit > 0 ? (
          <p className="module-credit">Credits: {totalCredit}</p>
        ) : ''}
        <Button variant="contained" className="btn btn-return" onClick={onCancel}>
          No, I don't want to continue
        </Button>
        <Button variant="contained" className="btn btn-cancel" onClick={onBook}>
          Continue Booking
        </Button>
      </div>
    </div>
  </>
);
const CancelBooking = ({onBook, onCancel, message, isCanceled, messageError}) => (
  <>
    {messageError ? (
      <div className="cancel-booking">
        <div className="cancel-missing">
          <AlertTriangle />
          <div className="cancel-missing__content">
            {messageError}
          </div>
        </div>
      </div>
    ): ''}
    {isCanceled ? (
      <div className="cancel-booking">
        <h2>Your module has been cancelled</h2>
        <div className="btn-block">
          <Button variant="contained" className="btn btn-return" onClick={onCancel}>Close</Button>
        </div>
      </div>
    ) : ''}
    {!isCanceled && !messageError ? (
      <div className="cancel-booking">
        <h2>Are you sure you want to cancel this booking?</h2>
        {(message && message.message) ? (
          <div className={`cancel-missing ${message.type !== 'red' ? 'cancel-missing--orange' : ''}`}>
            <AlertCircle />
            <div className="cancel-missing__content">
              {ReactHtmlParser(message.message)}
            </div>
          </div>
        ):''}
        <div className="btn-block">
          <Button variant="contained" className="btn btn-return" onClick={onCancel}>
            No, I don't want to cancel
          </Button>
          <Button variant="contained" className="btn btn-cancel" onClick={onBook}>
            Cancel booking
          </Button>
        </div>
      </div>
    ) : ''}
  </>
);
export default memo(ModuleBooking);
